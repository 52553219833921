<template>
  <div>
    <nav-bar v-if="showBackBtn" @onBack="handleBack" />
    <div v-if="isClose" class="category-task">
      <h3 class="task-name">{{ skuTaskName }}</h3>
      <div class="task-end-time">{{ effectiveTime }}</div>
      <div class="skuTaskUpload">
        <div class="skuTaskUploadFile">
          <span class="toolTip">{{ tips }}</span>
          <div class="category">
            <table border cellpadding="8">
              <tr>
                <th>品类名称</th>
                <th>数量</th>
                <th>操作</th>
              </tr>
              <tr v-for="(item, index) in categories" :key="index">
                <td class="cate" :class="[item.isUpdate ? 'update' : '']">
                  <span class="order">{{ index + 1 }}</span>
                  <div class="cate-input" @click.stop>
                    <span>{{ item.categoryName }}</span>
                  </div>
                </td>
                <td
                  class="count-input"
                  :class="[item.isUpdate ? 'update' : '']"
                >
                  <span v-if="item.isConfirm">{{ item.skuCountDelivery }}</span>
                  <input v-else v-model="item.skuCountDelivery" type="number" />
                </td>
                <td class="operation">
                  <div v-show="!finishSubmit" class="operation-box">
                    <template v-if="item.isConfirm">
                      <van-button
                        type="danger"
                        size="mini"
                        @click="updateCate(index, item)"
                        >修改</van-button
                      >
                    </template>
                    <van-button
                      v-else
                      type="danger"
                      size="mini"
                      @click="confirmCate(index, item)"
                      >确定</van-button
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="submit">
        <span v-if="finishSubmit">数据已确认</span>
        <van-button
          v-else
          class="skuTaskSubmit"
          type="danger"
          :loading="loading"
          loading-text="提交中..."
          @click="handleSubmit"
        >
          {{ btnText }}
        </van-button>
      </div>
    </div>
    <van-empty v-else description />
  </div>
</template>

<script>
import NavBar from "../components/nav-bar.vue";

import {
  getTaskDetails,
  getListCompetitorCategories,
  executeSkuCountReviewTask,
} from "@/apis";
import { formatDate } from "@/tools/date";
import { getUrlParams, userAccessValid } from "../shared";
import { Toast, Dialog } from "vant";
import backListMixins from "../mixins/back-list";

const COMPANY_ID = 81;
export default {
  name: "CategoryTask",
  components: {
    NavBar,
  },
  mixins: [backListMixins],
  data() {
    return {
      tips: "如果数据有明显出入，请帮忙修改后提交。",
      urlParams: {},
      skuTaskName: "",
      effectiveTime: "",
      loading: false,
      companyId: COMPANY_ID,
      isClose: true,
      taskEndTime: 0,
      categories: [],
      showListIndex: null,
      finishSubmit: false,
      prevSkuCount: [],
    };
  },
  computed: {
    btnText() {
      const isUpdate = this.categories.some((item) => item.isUpdate);
      return isUpdate ? "提交修改" : "确定";
    },
  },
  async created() {
    this.urlParams = getUrlParams(this.$route.query);
    await this.getUserAccessValid();
  },
  mounted() {
    document.addEventListener("click", () => {
      if (this.showListIndex !== null) {
        this.showListIndex = null;
      }
    });
  },
  methods: {
    // h5鉴权
    async getUserAccessValid() {
      try {
        const isValid = await userAccessValid(this.urlParams);
        this.isClose = isValid;
        if (isValid) {
          await this.getJobDetails();
          this.getListCompetitorCategories();
        }
      } catch {
        Toast.fail("鉴权失败");
      }
    },
    // 预加载前一天基线
    async getListCompetitorCategories() {
      try {
        const { jobId, cityId, userTaskId } = this.urlParams;
        const res = await getListCompetitorCategories({
          jobId,
          cityId,
          companyId: this.companyId,
          userTaskId,
        });
        if (res && res.data && res.data.length) {
          this.categories = res.data.map((item) => {
            item.isConfirm = true;
            this.prevSkuCount.push(item.skuCountDelivery);
            return item;
          });
        }
      } catch {
        Toast.fail("预加载品类异常！");
      }
    },
    // 获取任务详情
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams;
        let res = await getTaskDetails({ userTaskId, status: status ? +status : undefined });
        if (res) {
          let data = res.data || {};
          if (res.code !== 0)
            return Toast.fail(res.msg || "获取任务详情接口返回错误");
          let time = formatDate(new Date(data.jobEndTime), "yyyy-MM-dd");
          this.skuTaskName = data.name;
          this.effectiveTime = `数据时间: ${time}`;
        }
      } catch (err) {
        Toast.fail(err.message || "获取任务详情接口请求错误");
      }
    },
    updateCate(index) {
      this.categories = this.categories.map((item, i) => {
        if (i === index) {
          item.isConfirm = false;
        }
        return item;
      });
    },
    confirmCate(index) {
      this.categories = this.categories.map((item, i) => {
        if (i === index) {
          if (!/^[1-9]\d*$/.test(item.skuCountDelivery)) {
            Toast.fail("请完填写合法的品类数量信息");
            return item;
          }
          item.isConfirm = true;
          item.isUpdate = this.prevSkuCount[index] !== +item.skuCountDelivery;
        }
        return item;
      });
    },
    handleSubmit() {
      if (this.categories.some((item) => !item.isConfirm)) {
        Toast("请确认品类数量信息，再提交！");
        return;
      }
      const isUpdate = this.categories.some((item) => item.isUpdate);
      if (isUpdate) {
        Dialog.confirm({ title: "确定提交修改？" })
          .then(() => {
            this.confirmSubmit();
          })
          .catch(() => {});
      } else {
        this.confirmSubmit();
      }
    },
    async confirmSubmit() {
      this.loading = true;
      const skuCategoryList = this.categories.map((item, index) => {
        return {
          categoryName: item.categoryName,
          skuCountReview: +item.skuCountDelivery,
          categoryId: item.categoryId,
          order: index + 1,
        };
      });
      const { jobId, userTaskId, cityId } = this.urlParams;
      try {
        const res = await executeSkuCountReviewTask({
          jobId,
          userTaskId,
          companyId: this.companyId, // DD的companyId
          cityId,
          skuCategoryList,
        });
        this.loading = false;
        if (res) {
          this.loading = false;
          let msg = "数据已确认";
          let confirmButtonText = "完成关闭";
          if (res.code !== 0) {
            msg = res.msg || "抱歉数据确认失败！";
            confirmButtonText = "我再试试";
          }
          await Dialog["alert"]({
            message: msg,
            confirmButtonText,
          });
          if (res.code === 0) {
            this.finishSubmit = true;
          }
        }
      } catch {
        Toast.fail("任务提交异常！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task-name {
  padding: 0 20px;
  font-size: 16px;
  text-align: center;
}
.task-end-time {
  font-size: 14px;
}
.dialog-content-table {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  table {
    width: 80%;
    border-collapse: collapse;
    th {
      background: #f2f6fc;
    }
    tr {
      text-align: center;
    }
  }
}
.category-task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .skuTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e4e7ed;
    border-radius: 20px;
    .skuTaskUploadFile {
      .toolTip {
        display: inline-block;
        margin-bottom: 20px;
      }
    }
  }
  .category {
    background: #fff;
    table {
      width: 100%;
      border-collapse: collapse;
      border-color: #c0c4cc;
      th {
        background: #eee;
      }
      .cate {
        &.update {
          color: red;
        }
        .order {
          display: inline-block;
          width: 15px;
          text-align: center;
        }
        .cate-input {
          display: inline-block;
          width: 75%;
        }
      }
      .count-input {
        width: 30%;
        text-align: center;
        input {
          width: 80%;
          padding: 0;
          padding-left: 5px;
          line-height: 20px;
          border: none;
          border: 1px solid #c0c4cc;
        }
        &.update {
          color: red;
        }
      }
      .operation {
        width: 35%;
        .operation-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .van-button--mini {
          width: 45%;
        }
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20%;
    .skuTaskSubmit {
      width: 60%;
      color: #fff;
      background: #f56c6c;
      border-color: #f56c6c;
      border-radius: 16px;
    }
  }
}
</style>
